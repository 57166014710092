import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@dieta/web-dieta-components";
import Complete from "./Screens/Complete";
import Unauthorized from "./Screens/Unauthorized";
import Errored from "./Screens/Error";
import NotFound from "./Screens/NotFound";
import Survey from "./Screens/Survey";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Switch>
          <Route exact path="/complete" component={Complete} />
          <Route exact path="/401" component={Unauthorized} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={Errored} />
          <Route path="/(.*)" component={Survey} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;

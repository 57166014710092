import React from "react";
// import styled, { ThemeContext, keyframes } from "styled-components";
import axios from "axios";

import {
  // ThemeProvider,
  FormProvider,
  FormProgress,
  FormBody,
  FormFooter
} from "@dieta/web-dieta-components";
import { Redirect } from "react-router-dom";
import Authenticating from "./Authenticating";

const axiosInstance = axios.create({
  baseURL: "https://production-api-dieta.dietahealth.com"
  //baseURL: "http://localhost:1111"
});

const sleep = async (ms = 0) => {
  return new Promise(r => setTimeout(r, ms));
};

// Can update in place the states object (needed... mark complete)
const deepStatesMutate = (states, mutateOps, mergeProps = {}) => {
  return states.map(state => {
    if (state[mutateOps.source.key] === mutateOps.source.value) {
      if (!state[mutateOps.target.key]) {
        state = {
          ...state,
          [mutateOps.target.key]: null
        };
      }
      state[mutateOps.target.key] = mutateOps.target.value;
      state = {
        ...state,
        ...mergeProps
      };
    }
    if (state.states) {
      state.states = deepStatesMutate(state.states, mutateOps, mergeProps);
    }
    return state;
  });
};

function getUrlParams(search) {
  let hashes = search.slice(search.indexOf("?") + 1).split("&");
  let params = {};
  hashes.map(hash => {
    let [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });

  return params;
}

const Survey = class Survey extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    name: "authenticating",
    survey: {},
    token: "",
    bottomSpaceHeight: 0,
    currentState: "1",
    surveyStates: []
  };

  async componentDidMount() {
    const survey = this.props.location.pathname.replace("/", "");
    const uri = getUrlParams(this.props.location.search);
    if (!survey) {
      this.setState({ name: "empty" });
    }

    const jwt = uri.jwt;
    if (!jwt) {
      this.setState({ name: "unauthorized" });
    }
    if (uri.bottomInset) {
      this.setState({
        bottomSpaceHeight: parseInt(uri.bottomInset)
      })
    }

    try {
      await this.authenticate(survey, jwt);
      if (this.state.survey && this.state.survey.id) {
        await this.fetchStates();
        await this.reconcileStates();
      } else {
        this.setState({
          name: "complete"
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async authenticate(surveyName, jwt) {
    await sleep(500);
    try {
      const req = await axiosInstance.request({
        url: "v1/surveys?historical=true",
        method: "get",
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      const surveys = req.data.surveys;
      const requestedSurvey = surveys.find(survey => {
        return survey.name === surveyName;
      });
      this.setState({
        survey: requestedSurvey,
        name: "loading",
        jwt
      });
    } catch (err) {
      this.setState({
        name: "unauthorized"
      });
      throw err;
    }
  }

  async fetchStates() {
    try {
      const req = await axiosInstance.request({
        url: `v1/surveys/${this.state.survey.id}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${this.state.jwt}`
        }
      });
      const survey = req.data;
      this.setState({
        surveyStates: survey.states.data
      });
    } catch (err) {
      this.setState({
        name: "error"
      });
    }
  }

  async reconcileStates() {
    const req = await axiosInstance.request({
      url: `v1/questions?issuer=${this.state.survey.name}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${this.state.jwt}`
      }
    });
    let states = this.state.surveyStates;
    const answers = req.data;
    answers.questions.forEach(answer => {
      let formattedAnswer = [];
      if (answer.answer) {
        Object.keys(answer.answer).forEach(key => {
          if (isNaN(parseInt(key))) {
            formattedAnswer.push({ [key]: answer.answer[key] });
          } else {
            if (typeof answer.answer[key] === "object") {
              formattedAnswer = answer.answer[key];
            } else {
              formattedAnswer.push(answer.answer[key]);
            }
          }
        });
      }
      states = deepStatesMutate(states, {
        source: {
          key: "question",
          value: answer.question
        },
        target: {
          key: "answer",
          value: formattedAnswer
        }
      });
    });
    answers.questions.forEach(answer => {
      states = deepStatesMutate(states, {
        source: {
          key: "question",
          value: answer.question
        },
        target: {
          key: "complete",
          value: true
        }
      });
    });
    await sleep(1000);
    this.setState({
      surveyStates: states,
      name: "active"
    });
  }

  async answerQuestion(question, answer) {
    const rawAnswer = answer;
    let formattedAnswer;
    if (Array.isArray(rawAnswer)) {
      formattedAnswer = Object.assign({}, rawAnswer);
    } else {
      formattedAnswer = { 0: rawAnswer };
    }
    try {
      await axiosInstance.request({
        url: `v1/questions`,
        method: "post",
        headers: {
          Authorization: `Bearer ${this.state.jwt}`
        },
        data: {
          question,
          answer: formattedAnswer,
          issuer: this.state.survey.name
        }
      });
    } catch (err) {
      this.setState({
        name: "error"
      });
    }
  }

  async completeForm() {
    try {
      const req = await axiosInstance.request({
        url: `v1/surveys/${this.state.survey.id}`,
        method: "patch",
        headers: {
          Authorization: `Bearer ${this.state.jwt}`
        },
        data: {
          completed_at: new Date()
        }
      });
      this.setState({
        name: "complete"
      });
    } catch (err) {
      this.setState({
        name: "error"
      });
    }
  }

  render() {
    switch (this.state.name) {
      case "authenticating":
        return <Authenticating state={this.state.name} />;
      case "loading":
        return <Authenticating state={this.state.name} />;
      case "active":
        return (
          <FormProvider
            currentState={this.state.currentState}
            states={this.state.surveyStates}
            onFormCompleted={async () => {
              await this.completeForm();
              await sleep(2000);
              // Auto send them outta here (back from whence they came)
              window &&
                window.ReactNativeWebView &&
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    event: "onFormComplete",
                    survey: this.state.survey
                  }),
                  process.env.PUBLIC_URL || "http://localhost:3000"
                );
            }}
            onNextState={state => {
              if (state.question) {
                this.answerQuestion(state.question, state.answer);
              }
              window && window.scrollTo(0, 0);
            }}
            onAnsweredCallback={async (payload, context) => {
              context.markStateComplete(context.activeState, {
                answer: payload.answer
              });
              if (context.checkStateComplete(payload.answer)) {
                this.answerQuestion(payload.question, payload.answer);
              }
            }}
          >
            <FormProgress />
            <FormBody />
            <div style={{ height: 90 }} />
            <FormFooter bottomSpaceHeight={this.state.bottomSpaceHeight} />
          </FormProvider>
        );
      case "complete":
        return <Redirect to="/complete" />;
      case "unauthorized":
        return <Redirect to="/401" />;
      case "empty":
        return <Redirect to="/404" />;
      case "error":
        return <Redirect to="/500" />;
    }
  }
};

export default Survey;

import React from "react";
import styled, { ThemeContext, keyframes } from "styled-components";

const slash = keyframes`
  0%{
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    //height: 0;
    //width: calc(7em/4);
    opacity: 1;
  }
  40% {
    // height: calc(7em/2);
    // width: calc(7em/4);
    opacity: 1;
  }
  100% {
    height: calc(7em/2);
    //width: calc(7em/4);
    opacity: 1;
  }
`;

const shashDelayed = keyframes`
0%{
  height: 0;
  width: 0;
  opacity: 1;
}
50% {
  height: 0;
  width: 0;
  opacity: 1;
}
60% {
  //height: 0;
  //width: calc(7em/4);
  opacity: 1;
}
90% {
  // height: calc(7em/2);
  // width: calc(7em/4);
  opacity: 1;
}
100% {
  height: calc(7em/2);
  //width: calc(7em/4);
  opacity: 1;
}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh);
  width: calc(100vw);
`;

const SafteyWrapper = styled.div`
  border: 20px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
`;

const StyledCompleteContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.MID};
  margin: auto;
  top: 0;
  bottom: 0;
  padding: 30px;
  box-shadow: 0 0 50px ${props => props.theme.colors.MID};
`;

const StyledContentContainer = styled.div`
  position: relative;
  text-align: center;
  margin: auto;
`;

const StyledCircleLoader = styled.div`
  margin-bottom: 7em/2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-color: ${props => props.theme.colors.WARNING};
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
`;

const StyledCheckmark = styled.div`
  &:after {
    opacity: 1;
    animation: ${shashDelayed} ease 800ms;
    transform: translateX(calc(7em / 2)) translateY(calc(3em / 4)) scaleX(-1)
      rotate(315deg);
    height: calc(7em / 2);
    transform-origin: left top;
    border-right: ${props => "3px solid " + props.theme.colors.WARNING};

    content: "";
    left: calc(1.35em);
    top: calc(1.5em);
    position: absolute;
  }
  &:before {
    opacity: 1;
    animation: ${slash} ease 800ms;
    transform: translateX(calc(7em / 2)) translateY(calc(7em / 4)) scaleX(-1)
      rotate(45deg);
    height: calc(7em / 2);
    transform-origin: left top;
    border-right: ${props => "3px solid " + props.theme.colors.WARNING};
    content: "";
    left: calc(-1.15em);
    top: calc(0.4em);
    position: absolute;
  }
`;

const StyledUnauthorizedMessageHeader = styled.h3`
  font-family: proxima-nova;
  text-align: center;
`;

const StyledUnauthorizedMessageSubHeader = styled.h4`
  color: ${props => props.theme.colors.GRAY};
`;

export default class Unauthorized extends React.Component {
  render() {
    return (
      // <ThemeContext.Consumer>
      //   {themeContext => {
      //     console.log(themeContext);
      //     return (
      <Wrapper>
        <SafteyWrapper>
          <StyledCompleteContainer>
            <StyledContentContainer>
              <StyledCircleLoader>
                <StyledCheckmark />
              </StyledCircleLoader>
            </StyledContentContainer>

            <StyledContentContainer>
              <StyledUnauthorizedMessageHeader>
                Unauthorized! Link has expired!
              </StyledUnauthorizedMessageHeader>
              <StyledUnauthorizedMessageSubHeader>
                You've attempted to access a private site with an invalid or
                expired security token. <br />
                <br />
                Please revisit this page from an authorized client, or{" "}
                <a href="mailto:support@bigdieta.com">contact support</a> for
                help.
              </StyledUnauthorizedMessageSubHeader>
            </StyledContentContainer>
          </StyledCompleteContainer>
        </SafteyWrapper>
      </Wrapper>

      //     );
      //   }}
      // </ThemeContext.Consumer>
    );
  }
}

import React from "react";
import styled, { ThemeContext, keyframes } from "styled-components";

const checkmark = keyframes`
  0%{
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: calc(7em/4);
    opacity: 1;
  }
  40% {
    // height: calc(7em/2);
    // width: calc(7em/4);
    opacity: 1;
  }
  100% {
    height: calc(7em/2);
    width: calc(7em/4);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh);
  width: calc(100vw);
`;

const SafteyWrapper = styled.div`
  border: 20px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
`;

const StyledCompleteContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.MID};
  margin: auto;
  top: 0;
  bottom: 0;
  padding: 30px;
  box-shadow: 0 0 50px ${props => props.theme.colors.MID};
`;

const StyledContentContainer = styled.div`
  position: relative;
  text-align: center;
  margin: auto;
`;

const StyledCircleLoader = styled.div`
  margin-bottom: 7em/2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-color: ${props => props.theme.colors.PRIMARY_LIGHT};
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
`;

const StyledCheckmark = styled.div`
  &:after {
    opacity: 1;
    animation: ${checkmark} ease 800ms;
    transform: scaleX(-1) rotate(135deg);
    height: calc(7em / 2);
    width: calc(7em / 4);
    transform-origin: left top;
    border-right: ${props => "3px solid " + props.theme.colors.PRIMARY_LIGHT};
    border-top: ${props => "3px solid " + props.theme.colors.PRIMARY_LIGHT};
    content: "";
    left: calc(7em / 6 + 7em / 12);
    top: calc(7em / 2);
    position: absolute;
  }
`;

const StyledSuccessMessageHeader = styled.h3`
  font-family: proxima-nova;
  text-align: center;
`;

const StyledSuccessMessageSubHeader = styled.h4`
  color: ${props => props.theme.colors.GRAY};
`;

export default class Complete extends React.Component {
  render() {
    return (
      // <ThemeContext.Consumer>
      //   {themeContext => {
      //     console.log(themeContext);
      //     return (
      <Wrapper>
        <SafteyWrapper>
          <StyledCompleteContainer>
            <StyledContentContainer>
              <StyledCircleLoader>
                <StyledCheckmark />
              </StyledCircleLoader>
            </StyledContentContainer>

            <StyledContentContainer>
              <StyledSuccessMessageHeader>
                Success! You'll be redirected back shortly.
              </StyledSuccessMessageHeader>
              <StyledSuccessMessageSubHeader>
                Understanding your health is critical to providing quality
                analysis. <br />
                <br /> We'll provide further questions once your input is
                required.
              </StyledSuccessMessageSubHeader>
            </StyledContentContainer>
          </StyledCompleteContainer>
        </SafteyWrapper>
      </Wrapper>

      //     );
      //   }}
      // </ThemeContext.Consumer>
    );
  }
}
